@tailwind base;

@tailwind components;
@tailwind utilities;

img {
    border-radius: 50%;
    -webkit-transition: -webkit-transform .8s ease-in-out;
            transition:         transform .8s ease-in-out;
  }
  img:hover {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  
.text-golden {
  color: #f89a1e;
}